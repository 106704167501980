<template>
	<div class="navbar-container main-menu-content">
		<horizontal-nav-menu-items :items="navMenuItems" />
	</div>
</template>

<script>

	import HorizontalNavMenuItems from "./components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue"
	import { getUserData } from '@/auth/utils';

	export default {
		components: {
			HorizontalNavMenuItems
		},
		data () {
			return {
				navMenuItems: []
			}
		},
		mounted (){
			if (getUserData()) 
			{
				const { role } = getUserData();

				setTimeout(async () => {
					let mylib;

					if (role === 'admin' || role === 'superadmin'){
						mylib = await require('@/navigation/horizontal');
					} else if (role === 'provider') {
						mylib = await require('@/navigation/horizontal/index2');
					} else if (role === 'reviser') {
						mylib = await require('@/navigation/horizontal/index3');
					} else {
						mylib = await require('@/navigation/horizontal/index4');
					}

					this.navMenuItems = mylib.default;
				}, 300);
			}

		}
	}

</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>