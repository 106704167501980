<template>
    <layout-horizontal>

        <router-view />

        <template #navbar="{ toggleVerticalMenuActive }">
            <app-navbar-horizontal-layout-brand />
            <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        </template>
       
    </layout-horizontal>
</template>

<script>

    import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue';
    import LayoutHorizontal from '@core/layouts/layout-horizontal/LayoutHorizontal.vue';
    import { $themeConfig } from '@themeConfig';
    import Navbar from '../components/Navbar.vue';

    export default {
        components: {
            AppNavbarHorizontalLayoutBrand,
            LayoutHorizontal,
            Navbar
        },
        data() {
            return {
                showCustomizer: $themeConfig.layout.customizer
            }
        }
    }

</script>