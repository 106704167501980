export default [{
    header: 'Inicio',
    icon: 'HomeIcon',
    children: [{
        title: 'Página Principal',
        route: 'home',
        icon: 'BookmarkIcon',
        action: 'read',
        resource: 'ACL'
    }]
}]